<template>
  <div class="DialogReportPaid__Component">
    <form @submit.prevent="save" novalidate="true">
      <div class="modal-card">
        <header class="modal-card-head">
          <p class="modal-card-title">הוספת משתמש</p>
          <button type="button" class="delete" @click="$emit('close')" />
        </header>
        <section class="modal-card-body">
          <div class="DialogReportPaid__Component__Loading">
            <div class="field">
              <b-field label="שם מלא">
                <b-input type="text" v-model="$v.model.FullName.$model">
                </b-input>
              </b-field>
              <div>
                <span
                  v-if="!$v.model.FullName.required && $v.model.FullName.$error"
                  class="error"
                  >שדה חובה</span
                >
              </div>
            </div>
            <div class="field">
              <b-field label="אימייל">
                <b-input type="text" dir="ltr" v-model="$v.model.Email.$model">
                </b-input>
              </b-field>
              <div>
                <span
                  v-if="!$v.model.Email.required && $v.model.Email.$error"
                  class="error"
                  >שדה חובה</span
                >
              </div>
            </div>
            <div class="field">
              <b-field label="סיסמה">
                <b-input
                  type="password"
                  dir="ltr"
                  v-model="$v.model.Password.$model"
                  password-reveal
                >
                </b-input>
              </b-field>
              <div>
                <span
                  v-if="!$v.model.Password.required && $v.model.Password.$error"
                  class="error"
                  >שדה חובה</span
                >
              </div>
            </div>
            <div class="field">
              <b-field label="אימות סיסמה">
                <b-input
                  type="password"
                  dir="ltr"
                  v-model="$v.model.ConfirmPassword.$model"
                  password-reveal
                >
                </b-input>
              </b-field>
              <div>
                <span
                  v-if="
                    !$v.model.ConfirmPassword.sameAsPassword &&
                    $v.model.ConfirmPassword.$error
                  "
                  class="error"
                  >סיסמאות לא תואמות</span
                >
              </div>
            </div>
          </div>
        </section>
        <footer class="modal-card-foot">
          <b-button
            native-type="submit"
            :loading="isLoading"
            label="שמור"
            type="is-primary"
          />
          <b-button
            :disabled="isLoading"
            label="סגור"
            @click="$emit('close')"
          />
        </footer>
      </div>
    </form>
  </div>
</template>

<script>
import { ToastProgrammatic as Toast } from "buefy";
import AccountService from "@/services/AuthService";
import { required, sameAs, minLength } from "vuelidate/lib/validators";
import store from "../store";

export default {
  name: "DialogProfileUserAdd",
  props: ["onSuccess"],
  data() {
    return {
      isLoading: false,
      model: {
        FullName: null,
        Password: null,
        ConfirmPassword: null,
        Email: null,
      },
      errors: {},
    };
  },
  validations: {
    model: {
      FullName: { required },
      Password: {
        required,
        minLength: minLength(6),
      },
      ConfirmPassword: {
        required,
        sameAsPassword: sameAs("Password"),
      },
      Email: { required },
    },
  },
  methods: {
    save() {
      this.$v.$touch();
      if (this.$v.$invalid) {
        return;
      }
      this.isLoading = true;

      AccountService.register({
        ...this.model,
        ProfileId: store.state.profile.Id,
      })
        .then(() => {
          this.$emit("close");
          this.onSuccess();
          Toast.open({
            type: "is-success",
            message: "הפעולה בוצעה!",
            duration: 4000,
          });
        })
        .catch((err) => {
          if (err.response?.status === 400) {
            Toast.open({
              type: "is-danger",
              message: err.response?.data?.ModelState[""][0],
              duration: 8000,
            });
          }
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
  },
};
</script>

<style lang="scss">
.error {
  color: red;
  font-size: 15px;
}
</style>
