<template>
  <div class="Profile__General__Page">
    <div
      class="
        Profile__General__Page__Header
        is-flex is-justify-content-space-between
      "
    >
      <h1>ניהול משתמשים</h1>
      <b-button @click="addUser" type="is-primary">הוספת משתמש</b-button>
    </div>
    <div class="Profile__General__Page__Form">
      <div class="Profile__General__Page__Form__Field">
        <b-table
          :data="users"
          :loading="isLoading"
          :bordered="true"
          :striped="true"
          :narrowed="false"
          :hoverable="false"
        >
          <b-table-column centered field="Id" label="מזהה" v-slot="props">
            {{ props.row.Id }}
          </b-table-column>
          <b-table-column
            sortable
            centered
            field="FullName"
            label="שם מלא"
            v-slot="props"
          >
            {{ props.row.FullName }}
          </b-table-column>
          <b-table-column
            sortable
            centered
            field="UserName"
            label="שם משתמש"
            v-slot="props"
          >
            {{ props.row.UserName }}
          </b-table-column>
          <b-table-column centered field="" label="הרשאות" v-slot="props">
            <b-button
              @click="changePermissions(props.row.Id, props.row.Claims)"
              type="is-primary"
              >ערוך</b-button
            >
          </b-table-column>
          <b-table-column centered field="" label="סיסמה" v-slot="props">
            <b-button @click="changePassword(props.row.Id)" type="is-primary"
              >שנה</b-button
            >
          </b-table-column>
          <b-table-column centered field="" label="מחיקה" v-slot="props">
            <b-button @click="deleteUser(props.row.Id)" type="is-primary"
              >מחק</b-button
            >
          </b-table-column>
        </b-table>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions, mapMutations } from "vuex";
import DialogProfileUserChangePassword from "@/components/DialogProfileUserChangePassword.vue";
import DialogProfileUserAdd from "@/components/DialogProfileUserAdd.vue";
import ProfileService from "@/services/ProfileService";
import DialogProfileUserPermission from "@/components/DialogProfileUserPermission.vue";

export default {
  name: "ProfileUsers",
  data() {
    return {
      isLoading: true,
      users: [],
    };
  },
  computed: {
    ...mapGetters("profile", ["getProfileUsers"]),
    profileId() {
      return this.$store.state.profile.Id;
    },
  },
  async created() {
    this.init();
  },
  methods: {
    ...mapMutations("profile", ["setProfileSettings"]),
    ...mapActions("profile", ["getUsers"]),
    showSuccessMessage() {
      this.$buefy.toast.open({
        type: "is-success",
        message: "נשמר בהצלחה!",
        duration: 4000,
      });
    },
    changePassword(userId) {
      this.$buefy.modal.open({
        component: DialogProfileUserChangePassword,
        canCancel: false,
        hasModalCard: true,
        trapFocus: true,
        props: {
          userId,
        },
      });
    },
    deleteUser(userId) {
      this.$buefy.dialog.confirm({
        title: "מחיקת משתמש",
        message: "האם למחוק את המשתמש?",
        confirmText: "כן",
        cancelText: "לא",
        type: "is-primary",
        closeOnConfirm: false,
        hasIcon: true,
        onConfirm: (a, instance) => {
          this.isLoading = true;
          instance.close();
          ProfileService.deleteUser(this.$store.state.auth.profileId, userId)
            .then(() => {
              this.$buefy.dialog.alert({
                type: "is-success",
                message: "הפעולה בוצעה בהצלחה!",
                confirmText: 'אוקיי'
              });
              this.isLoading = false;
              instance.close();
              window.location.reload();
            })
            .catch(() => {
              this.$buefy.dialog.alert({
                type: "is-danger",
                message: "אירעה שגיאה!",
                hasIcon: true,
                icon: 'times-circle',
                iconPack: 'fa',
                confirmText: 'אוקיי',
              });
              this.isLoading = false;
              instance.close();
            })
            .finally(() => {
              this.isLoading = false;
              instance.close();
            });
        },
      });
    },
    changePermissions(userId, claims) {
      this.$buefy.modal.open({
        component: DialogProfileUserPermission,
        canCancel: false,
        hasModalCard: true,
        trapFocus: true,
        props: {
          profileId: this.profileId,
          userId,
          currentClaims: claims,
        },
        events: {
          close: (result) => {
            if (result) {
              const user = this.users.find((u) => u.Id === userId);
              if (user) {
                this.$set(user, "Claims", [...result]);
              }
            }
          },
        },
      });
    },
    addUser() {
      this.$buefy.modal.open({
        component: DialogProfileUserAdd,
        canCancel: false,
        hasModalCard: true,
        trapFocus: true,
        props: {
          onSuccess: this.init,
        },
      });
    },
    async init() {
      this.isLoading = true;

      if (this.profileId) {
        await this.getUsers(this.profileId);
        this.users = [...this.getProfileUsers];
        this.isLoading = false;
      }
    },
  },
  watch: {
    async profileId(newValue) {
      if (newValue) {
        const users = await this.getUsers(newValue);
        this.users = [...users];
        this.isLoading = false;
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.Profile__General__Page {
  padding: 20px 40px;
  .Profile__General__Page__Header {
    border-bottom: 1px solid silver;
    padding: 0px 10px 10px 10px;
    h1 {
      font-size: 30px;
    }
  }
  .Profile__General__Page__Form {
    margin-top: 20px;

    .Profile__General__Page__Form__Field {
      margin-top: 20px;

      .switch,
      .control {
        direction: ltr;
      }

      ::v-deep {
        .field label {
          font-size: 15px;
          color: rgb(44, 62, 80);
        }
        input {
          background-color: #f5f8fa;
          box-shadow: none;
          border: 1px solid #cbd6e2;

          &:focus {
            border: 2px solid #5fe0ea;
          }
        }
      }
    }
    .Profile__General__Page__Form__Actions {
      ::v-deep .button {
        margin-inline-end: 5px;
        background-color: #4d6884;
        color: white;
        border: none;
        border-radius: 3px;
        padding: 6px 15px;
        line-height: normal;
        height: unset;
        cursor: pointer;
        box-shadow: none;
        transition: transfrom 0.2s linear;

        &:hover {
          background-color: #54779c;
          border: none;
          outline: none;
        }
        &:active {
          transform: scale(0.98);
          border: none;
          outline: none;
        }
      }
    }
  }
}
</style>
