var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "DialogReportPaid__Component" }, [
    _c(
      "form",
      {
        attrs: { novalidate: "true" },
        on: {
          submit: function($event) {
            $event.preventDefault()
            return _vm.save($event)
          }
        }
      },
      [
        _c("div", { staticClass: "modal-card" }, [
          _c("header", { staticClass: "modal-card-head" }, [
            _c("p", { staticClass: "modal-card-title" }, [
              _vm._v("הגדרת סיסמה חדשה")
            ]),
            _c("button", {
              staticClass: "delete",
              attrs: { type: "button" },
              on: {
                click: function($event) {
                  return _vm.$emit("close")
                }
              }
            })
          ]),
          _c("section", { staticClass: "modal-card-body" }, [
            _c(
              "div",
              { staticClass: "DialogReportPaid__Component__Loading" },
              [
                _c(
                  "b-field",
                  { attrs: { label: "סיסמה", message: _vm.errors.length } },
                  [
                    _c("b-input", {
                      attrs: {
                        type: "password",
                        dir: "ltr",
                        minlength: "6",
                        "password-reveal": ""
                      },
                      model: {
                        value: _vm.password,
                        callback: function($$v) {
                          _vm.password = $$v
                        },
                        expression: "password"
                      }
                    })
                  ],
                  1
                ),
                _c(
                  "b-field",
                  {
                    attrs: {
                      label: "אימות סיסמה",
                      message: _vm.errors.match || _vm.errors.chars
                    }
                  },
                  [
                    _c("b-input", {
                      attrs: {
                        type: "password",
                        dir: "ltr",
                        minlength: "6",
                        "password-reveal": ""
                      },
                      model: {
                        value: _vm.confirmPassword,
                        callback: function($$v) {
                          _vm.confirmPassword = $$v
                        },
                        expression: "confirmPassword"
                      }
                    })
                  ],
                  1
                )
              ],
              1
            )
          ]),
          _c(
            "footer",
            { staticClass: "modal-card-foot" },
            [
              _c("b-button", {
                attrs: {
                  "native-type": "submit",
                  loading: _vm.isLoading,
                  label: "שמור",
                  type: "is-primary"
                }
              }),
              _c("b-button", {
                attrs: { disabled: _vm.isLoading, label: "סגור" },
                on: {
                  click: function($event) {
                    return _vm.$emit("close")
                  }
                }
              })
            ],
            1
          )
        ])
      ]
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }