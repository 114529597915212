var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "Profile__General__Page" }, [
    _c(
      "div",
      {
        staticClass:
          "\n      Profile__General__Page__Header\n      is-flex is-justify-content-space-between\n    "
      },
      [
        _c("h1", [_vm._v("ניהול משתמשים")]),
        _c(
          "b-button",
          { attrs: { type: "is-primary" }, on: { click: _vm.addUser } },
          [_vm._v("הוספת משתמש")]
        )
      ],
      1
    ),
    _c("div", { staticClass: "Profile__General__Page__Form" }, [
      _c(
        "div",
        { staticClass: "Profile__General__Page__Form__Field" },
        [
          _c(
            "b-table",
            {
              attrs: {
                data: _vm.users,
                loading: _vm.isLoading,
                bordered: true,
                striped: true,
                narrowed: false,
                hoverable: false
              }
            },
            [
              _c("b-table-column", {
                attrs: { centered: "", field: "Id", label: "מזהה" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function(props) {
                      return [_vm._v(" " + _vm._s(props.row.Id) + " ")]
                    }
                  }
                ])
              }),
              _c("b-table-column", {
                attrs: {
                  sortable: "",
                  centered: "",
                  field: "FullName",
                  label: "שם מלא"
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function(props) {
                      return [_vm._v(" " + _vm._s(props.row.FullName) + " ")]
                    }
                  }
                ])
              }),
              _c("b-table-column", {
                attrs: {
                  sortable: "",
                  centered: "",
                  field: "UserName",
                  label: "שם משתמש"
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function(props) {
                      return [_vm._v(" " + _vm._s(props.row.UserName) + " ")]
                    }
                  }
                ])
              }),
              _c("b-table-column", {
                attrs: { centered: "", field: "", label: "הרשאות" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function(props) {
                      return [
                        _c(
                          "b-button",
                          {
                            attrs: { type: "is-primary" },
                            on: {
                              click: function($event) {
                                return _vm.changePermissions(
                                  props.row.Id,
                                  props.row.Claims
                                )
                              }
                            }
                          },
                          [_vm._v("ערוך")]
                        )
                      ]
                    }
                  }
                ])
              }),
              _c("b-table-column", {
                attrs: { centered: "", field: "", label: "סיסמה" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function(props) {
                      return [
                        _c(
                          "b-button",
                          {
                            attrs: { type: "is-primary" },
                            on: {
                              click: function($event) {
                                return _vm.changePassword(props.row.Id)
                              }
                            }
                          },
                          [_vm._v("שנה")]
                        )
                      ]
                    }
                  }
                ])
              }),
              _c("b-table-column", {
                attrs: { centered: "", field: "", label: "מחיקה" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function(props) {
                      return [
                        _c(
                          "b-button",
                          {
                            attrs: { type: "is-primary" },
                            on: {
                              click: function($event) {
                                return _vm.deleteUser(props.row.Id)
                              }
                            }
                          },
                          [_vm._v("מחק")]
                        )
                      ]
                    }
                  }
                ])
              })
            ],
            1
          )
        ],
        1
      )
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }