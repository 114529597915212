<template>
  <div class="DialogReportPaid__Component">
    <div class="modal-card">
      <header class="modal-card-head">
        <p class="modal-card-title">שינוי הרשאות למשתמש</p>
        <button type="button" class="delete" @click="$emit('close')" />
      </header>
      <section class="modal-card-body">
        <div class="claim-field" v-for="claim in claims" :key="claim.Id">
          <span>{{ claim.Name }}</span>
          <b-switch
            v-model="claim.selected"
            :true-value="true"
            :false-value="false"
          >
            {{ claim.selected ? "פעיל" : "" }}
          </b-switch>
        </div>
      </section>
      <footer class="modal-card-foot">
        <b-button
          :loading="isLoading"
          @click="save"
          label="שמור"
          type="is-primary"
        />
        <b-button :disabled="isLoading" label="סגור" @click="$emit('close')" />
      </footer>
    </div>
  </div>
</template>

<script>
import { ToastProgrammatic as Toast } from "buefy";
import UserClaimsService from "@/services/UserClaimsService";
import ProfileService from "@/services/ProfileService";

export default {
  name: "DialogProfileUserPermission",
  props: ["profileId", "userId", "currentClaims"],
  data() {
    return {
      isLoading: false,
      claims: [],
    };
  },
  async created() {
    const claims = await UserClaimsService.get(true);
    this.claims = [...claims];
    this.currentClaims.forEach((c) => {
      const claim = this.claims.find((i) => i.Id === c.Id);
      this.$set(claim, "selected", true);
    });
  },
  methods: {
    save() {
      ProfileService.updateClaims(
        this.profileId,
        this.userId,
        this.claims.filter((i) => i.selected)
      )
        .then((r) => {
          this.$emit("close", r.data.Claims);
          Toast.open({
            type: "is-success",
            message: "הפעולה בוצעה!",
            duration: 4000,
          });
        })
        .catch((err) => {
          console.log(err);
          Toast.open({
            type: "is-danger",
            message: "קרתה תקלה, לא היה ניתן לשמור את השינויים!",
            duration: 8000,
          });
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
  },
};
</script>

<style lang="scss">
.switch {
  direction: ltr;
}
.claim-field {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin: 20px 0;
}
</style>
