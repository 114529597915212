<template>
  <div class="DialogReportPaid__Component">
    <form @submit.prevent="save" novalidate="true">
      <div class="modal-card">
        <header class="modal-card-head">
          <p class="modal-card-title">הגדרת סיסמה חדשה</p>
          <button type="button" class="delete" @click="$emit('close')" />
        </header>
        <section class="modal-card-body">
          <div class="DialogReportPaid__Component__Loading">
            <b-field label="סיסמה" :message="errors.length">
              <b-input
                type="password"
                dir="ltr"
                v-model="password"
                minlength="6"
                password-reveal
              >
              </b-input>
            </b-field>
            <b-field
              label="אימות סיסמה"
              :message="errors.match || errors.chars"
            >
              <b-input
                type="password"
                dir="ltr"
                v-model="confirmPassword"
                minlength="6"
                password-reveal
              >
              </b-input>
            </b-field>
          </div>
        </section>
        <footer class="modal-card-foot">
          <b-button
            native-type="submit"
            :loading="isLoading"
            label="שמור"
            type="is-primary"
          />
          <b-button
            :disabled="isLoading"
            label="סגור"
            @click="$emit('close')"
          />
        </footer>
      </div>
    </form>
  </div>
</template>

<script>
import { ToastProgrammatic as Toast } from "buefy";
import AccountService from "@/services/AuthService";

export default {
  name: "DialogProfileUserChangePassword",
  props: ["userId"],
  data() {
    return {
      isLoading: false,
      password: null,
      confirmPassword: null,
      errors: {},
    };
  },
  methods: {
    save() {
      this.errors = {};
      if (!this.password || this.password.length < 6) {
        this.$set(
          this.errors,
          "length",
          "אורך סיסמה חייב להיות 6 תוים לפחות לפחות"
        );
        return;
      }
      if (this.password !== this.confirmPassword) {
        this.$set(this.errors, "match", "שדה הסיסמה אינו תואם לשדה האימות");
        return;
      }

      this.isLoading = true;
      AccountService.resetUserPassword({
        NewPassword: this.password,
        UserId: this.userId,
      })
        .then(() => {
          this.$emit("close");
          Toast.open({
            type: "is-success",
            message: "הפעולה בוצעה!",
            duration: 4000,
          });
        })
        .catch((err) => {
          this.$set(
            this.errors,
            "chars",
            Object.values(err.response.data.ModelState)[0][0]
          );
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
  },
};
</script>

<style lang="scss">
.help {
  color: red;
  font-size: 15px;
}
</style>
