<template>
  <div class="Profile__General__Page">
    <div class="Profile__General__Page__Header">
        <h1>מסמכים וטפסים</h1>
    </div>
    <div class="Profile__General__Page__Form">
      <div class="Profile__General__Page__Form__Field">
        <b-field label="יפוי כוח">
            <b-button
              class="ml-3"
              :loading="POAIsLoading"
              @click="showDocument('POA')"
              icon-left="file-document-edit-outline">
              {{HasPOA ? 'ערוך מסמך' : 'צור מסמך'}}
            </b-button>
            <b-button
             :loading="DeletePOAIsLoading"
              v-if="HasPOA"
              @click="deleteDocument('POA')"
              icon-left="file-document-edit-outline">
              מחק מסמך
            </b-button>
        </b-field>
        <b-field label="תבנית הסבה">
            <b-button
            class="ml-3"
            :loading="TransferFormIsLoading"
              @click="showDocument('TransferForm')"
              icon-left="file-document-edit-outline">
              {{HasTransferForm ? 'ערוך מסמך' : 'צור מסמך'}}
            </b-button>
            <b-button
             :loading="DeleteTransferFormIsLoading"
              v-if="HasTransferForm"
              @click="deleteDocument('TransferForm')"
              icon-left="file-document-edit-outline">
              מחק מסמך
            </b-button>
        </b-field>
        <b-field label='טופס החתמת עו"ד'>
            <b-button
            class="ml-3"
             :loading="LawyerTransferFormIsLoading"
              @click="showDocument('LawyerTransferForm')"
              icon-left="file-document-edit-outline">
              {{HasLawyerTransferForm ? 'ערוך מסמך' : 'צור מסמך'}}
            </b-button>
             <b-button
              :loading="DeleteLawyerTransferFormIsLoading"
              v-if="HasLawyerTransferForm"
              @click="deleteDocument('LawyerTransferForm')"
              icon-left="file-document-edit-outline">
              מחק מסמך
            </b-button>
        </b-field>
      </div>
    </div>
  </div>
</template>

<script>
import { mapMutations } from 'vuex';
import ProfileService from '@/services/ProfileService';
// import { ToastProgrammatic as Toast } from 'buefy';

export default {
  name: 'ProfileTest',
  data() {
      return {
        POAIsLoading: false,
        DeletePOAIsLoading: false,
        TransferFormIsLoading: false,

        DeleteTransferFormIsLoading: false,
        LawyerTransferFormIsLoading: false,
        DeleteLawyerTransferFormIsLoading: false,
      };
  },
  computed: {
    HasTransferForm() {
      return this.$store.state.profile.HasTransferForm;
    },
    HasLawyerTransferForm() {
      return this.$store.state.profile.HasLawyerTransferForm;
    },
    HasPOA() {
      return this.$store.state.profile.HasPOA;
    },
  },
  created() {

  },
  methods: {
    ...mapMutations('profile', [
      'setProfile',
    ]),
    async showDocument(type) {
      let html = null;
      if (this[`Has${type}`]) {
        this.$set(this, [`${type}IsLoading`], true);
        const { data } = await ProfileService.getDocument(type);
        html = data;
        this.$set(this, [`${type}IsLoading`], false);
      }
      this.$iframe.open({
            html,
            type,
            onSave: async ({ data, close }) => {
                if (data.close) {
                    close();
                } else {
                  close();
                  this.$set(this, [`${type}IsLoading`], true);
                    ProfileService.updateDocument(type, data.html).then(() => {
                      this.setProfile({ [`Has${type}`]: true });
                    }).finally(() => {
                      this.$set(this, [`${type}IsLoading`], false);
                    });
                }
            },
        });
      },
    deleteDocument(type) {
       this.$set(this, [`Delete${type}IsLoading`], true);
        ProfileService.deleteDocument(type)
        .then(() => {
          this.setProfile({ [`Has${type}`]: false });
          this.$set(this, [`Delete${type}IsLoading`], true);
        });
      },
  },
};
</script>
<style lang="scss" scoped>
.Profile__General__Page {
  padding: 20px 40px;
  .Profile__General__Page__Header {
    border-bottom: 1px solid silver;
    padding: 0px 10px 10px 10px;
    h1 {
      font-size: 30px;
    }
  }
  .Profile__General__Page__Form {
    margin-top: 20px;
    max-width: 450px;

    .Profile__General__Page__Form__Field {
      margin-top: 20px;

      ::v-deep  {
        .field label {
          font-size: 15px;
          color: rgb(44, 62, 80);
        }
        input {
          background-color: #F5F8FA;
          box-shadow: none;
          border: 1px solid #CBD6E2;

          &:focus {
            border: 2px solid #5fe0ea;
          }
        }
      }

    }
    .Profile__General__Page__Form__Actions {
      margin-top: 50px;

      ::v-deep .button {
        margin-inline-end: 5px;
        background-color: #4d6884;
        color: white;
        border: none;
        border-radius: 3px;
        padding: 6px 15px;
        line-height: normal;
        height: unset;
        cursor: pointer;
        box-shadow: none;
        transition: transfrom .2s linear;

        &:hover {
          background-color: #54779c;
          border: none;
          outline: none;
        }
        &:active {
          transform: scale(.98);
          border: none;
          outline: none;
        }
      }
    }
  }
}
</style>
