import axios from 'axios';

const BASE_URL = process.env.VUE_APP_APIURL;

const get = (isForce) => (!isForce && get.cache ? Promise.resolve(get.cache) : axios.get(`${BASE_URL}/api/UserClaims`).then((r) => {
 get.cache = r.data;
    return get.cache;
}
));

export default {
    get,
};
