var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "Profile__Page" }, [
    _c("div", { staticClass: "Profile__Page__Menu" }, [
      _c("h1", [_vm._v("פרופיל משתמש")]),
      _c("ul", [
        _c(
          "li",
          [
            _c("router-link", { attrs: { to: "general" } }, [
              _vm._v(" פרטי מנוי ")
            ])
          ],
          1
        ),
        _vm.isAdmin
          ? _c(
              "li",
              [
                _c("router-link", { attrs: { to: "settings" } }, [
                  _vm._v(" הגדרות ")
                ])
              ],
              1
            )
          : _vm._e(),
        _c(
          "li",
          [
            _c("router-link", { attrs: { to: "documents" } }, [
              _vm._v(" מסמכים וטפסים ")
            ])
          ],
          1
        ),
        _vm.isAdmin
          ? _c(
              "li",
              [
                _c("router-link", { attrs: { to: "users" } }, [
                  _vm._v(" ניהול משתמשים ")
                ])
              ],
              1
            )
          : _vm._e()
      ])
    ]),
    _c(
      "div",
      { staticClass: "Profile__Page__Content" },
      [_vm.ready ? _c("router-view") : _vm._e()],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }