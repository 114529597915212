var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "Profile__General__Page" }, [
    _vm._m(0),
    _c("div", { staticClass: "Profile__General__Page__Form" }, [
      _c(
        "div",
        { staticClass: "Profile__General__Page__Form__Field" },
        [
          _c(
            "b-field",
            { attrs: { label: "יפוי כוח" } },
            [
              _c(
                "b-button",
                {
                  staticClass: "ml-3",
                  attrs: {
                    loading: _vm.POAIsLoading,
                    "icon-left": "file-document-edit-outline"
                  },
                  on: {
                    click: function($event) {
                      return _vm.showDocument("POA")
                    }
                  }
                },
                [
                  _vm._v(
                    " " + _vm._s(_vm.HasPOA ? "ערוך מסמך" : "צור מסמך") + " "
                  )
                ]
              ),
              _vm.HasPOA
                ? _c(
                    "b-button",
                    {
                      attrs: {
                        loading: _vm.DeletePOAIsLoading,
                        "icon-left": "file-document-edit-outline"
                      },
                      on: {
                        click: function($event) {
                          return _vm.deleteDocument("POA")
                        }
                      }
                    },
                    [_vm._v(" מחק מסמך ")]
                  )
                : _vm._e()
            ],
            1
          ),
          _c(
            "b-field",
            { attrs: { label: "תבנית הסבה" } },
            [
              _c(
                "b-button",
                {
                  staticClass: "ml-3",
                  attrs: {
                    loading: _vm.TransferFormIsLoading,
                    "icon-left": "file-document-edit-outline"
                  },
                  on: {
                    click: function($event) {
                      return _vm.showDocument("TransferForm")
                    }
                  }
                },
                [
                  _vm._v(
                    " " +
                      _vm._s(_vm.HasTransferForm ? "ערוך מסמך" : "צור מסמך") +
                      " "
                  )
                ]
              ),
              _vm.HasTransferForm
                ? _c(
                    "b-button",
                    {
                      attrs: {
                        loading: _vm.DeleteTransferFormIsLoading,
                        "icon-left": "file-document-edit-outline"
                      },
                      on: {
                        click: function($event) {
                          return _vm.deleteDocument("TransferForm")
                        }
                      }
                    },
                    [_vm._v(" מחק מסמך ")]
                  )
                : _vm._e()
            ],
            1
          ),
          _c(
            "b-field",
            { attrs: { label: 'טופס החתמת עו"ד' } },
            [
              _c(
                "b-button",
                {
                  staticClass: "ml-3",
                  attrs: {
                    loading: _vm.LawyerTransferFormIsLoading,
                    "icon-left": "file-document-edit-outline"
                  },
                  on: {
                    click: function($event) {
                      return _vm.showDocument("LawyerTransferForm")
                    }
                  }
                },
                [
                  _vm._v(
                    " " +
                      _vm._s(
                        _vm.HasLawyerTransferForm ? "ערוך מסמך" : "צור מסמך"
                      ) +
                      " "
                  )
                ]
              ),
              _vm.HasLawyerTransferForm
                ? _c(
                    "b-button",
                    {
                      attrs: {
                        loading: _vm.DeleteLawyerTransferFormIsLoading,
                        "icon-left": "file-document-edit-outline"
                      },
                      on: {
                        click: function($event) {
                          return _vm.deleteDocument("LawyerTransferForm")
                        }
                      }
                    },
                    [_vm._v(" מחק מסמך ")]
                  )
                : _vm._e()
            ],
            1
          )
        ],
        1
      )
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "Profile__General__Page__Header" }, [
      _c("h1", [_vm._v("מסמכים וטפסים")])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }