var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "DialogReportPaid__Component" }, [
    _c("div", { staticClass: "modal-card" }, [
      _c("header", { staticClass: "modal-card-head" }, [
        _c("p", { staticClass: "modal-card-title" }, [
          _vm._v("שינוי הרשאות למשתמש")
        ]),
        _c("button", {
          staticClass: "delete",
          attrs: { type: "button" },
          on: {
            click: function($event) {
              return _vm.$emit("close")
            }
          }
        })
      ]),
      _c(
        "section",
        { staticClass: "modal-card-body" },
        _vm._l(_vm.claims, function(claim) {
          return _c(
            "div",
            { key: claim.Id, staticClass: "claim-field" },
            [
              _c("span", [_vm._v(_vm._s(claim.Name))]),
              _c(
                "b-switch",
                {
                  attrs: { "true-value": true, "false-value": false },
                  model: {
                    value: claim.selected,
                    callback: function($$v) {
                      _vm.$set(claim, "selected", $$v)
                    },
                    expression: "claim.selected"
                  }
                },
                [_vm._v(" " + _vm._s(claim.selected ? "פעיל" : "") + " ")]
              )
            ],
            1
          )
        }),
        0
      ),
      _c(
        "footer",
        { staticClass: "modal-card-foot" },
        [
          _c("b-button", {
            attrs: {
              loading: _vm.isLoading,
              label: "שמור",
              type: "is-primary"
            },
            on: { click: _vm.save }
          }),
          _c("b-button", {
            attrs: { disabled: _vm.isLoading, label: "סגור" },
            on: {
              click: function($event) {
                return _vm.$emit("close")
              }
            }
          })
        ],
        1
      )
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }