<template>
  <div class="Profile__General__Page">
    <div class="Profile__General__Page__Header">
      <h1>פרטי מנוי</h1>
    </div>
    <div class="Profile__General__Page__Form">
      <div class="Profile__General__Page__Form__Field">
        <b-field label="שם חברה/לקוח">
          <b-input v-model="model.Name"></b-input>
        </b-field>
      </div>
      <div class="Profile__General__Page__Form__Field">
        <b-field label="ח.פ">
          <b-input v-model="model.IdNumber"></b-input>
        </b-field>
      </div>
      <div class="Profile__General__Page__Form__Field">
        <b-field label="מייל">
          <b-input type="email" v-model="model.Email"></b-input>
        </b-field>
      </div>
      <div v-if="model.IsForeignCitizen" class="Profile__General__Page__Form__Field">
        <b-field label="עיר">
          <b-input v-model="model.City"></b-input>
        </b-field>
      </div>
      <div v-if="model.IsForeignCitizen" class="Profile__General__Page__Form__Field">
        <b-field label="רחוב">
          <b-input v-model="model.Street"></b-input>
        </b-field>
      </div>
      <FieldInlineSelect v-if="!model.IsForeignCitizen" :alert-not-exist="true" :hide-hover="true" label="עיר" :clearable="true" :filterable="true"
        :null-is-value="true" placeholder="הקלד עיר" :options="[]" :fetch-options="onSearchCities"
        @onChange="onChange" :reduce="(item) => item.Text" v-model="model.City" />

      <FieldInlineSelect v-if="!model.IsForeignCitizen" :readOnly="!model.City" :alert-not-exist="true" :hide-hover="true" label="רחוב" :clearable="true" :filterable="true"
        :null-is-value="true" placeholder="הקלד רחוב" :options="[]" :fetch-options="onSearchCityAddress"
        @onChange="onChange" :reduce="(item) => item" v-model="model.Street" />
      <div class="Profile__General__Page__Form__Field">
        <b-field label="מספר בית">
          <b-input v-model="model.HouseNumber"></b-input>
        </b-field>
      </div>
      <div class="Profile__General__Page__Form__Field">
        <b-field label="מיקוד">
          <b-input v-model="model.ZipCode"></b-input>
        </b-field>
        <b-field label="תייר">
          <b-switch class="ltr" v-model="model.IsForeignCitizen">{{
            model.IsForeignCitizen ? "כן" : "לא"
          }}</b-switch>
        </b-field>
        <div class="Profile__General__Page__Form__Actions">
          <b-button :loading="isLoading" @click="save">שמור</b-button>
          <b-button :disabled="isLoading" @click="reset">בטל</b-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from "vuex";
import FieldInlineSelect from "@/components/Fields/FieldInlineSelect.vue";
import { ToastProgrammatic as Toast } from "buefy";
import DriverService from "../services/DriverService";

export default {
  name: "ProfileGeneral",
  components: {
    FieldInlineSelect
  },
  data() {
    return {
      isLoading: false,
      model: {
        City: null,
        HouseNumber: null,
        IdNumber: null,
        Email: null,
        Name: null,
        Street: null,
        ZipCode: null,
        IsForeignCitizen: null,
      },
    };
  },
  computed: {
    ...mapGetters("profile", ["profileGeneral"]),
    profileId() {
      return this.$store.state.profile.Id;
    },
  },
  created() {
    this.model = { ...this.model, ...this.profileGeneral };
  },
  methods: {
    ...mapMutations("profile", ["updateGeneral"]),
    ...mapActions("profile", ["saveProfile"]),
    reset() {
      this.model = { ...this.model, ...this.profileGeneral };
    },
    save() {
      this.isLoading = true;
      const data = { ...this.model };
      data.Street = this.model.Street?.Text;
      this.updateGeneral(data);
      this.saveProfile()
        .then(() => {
          Toast.open({
            type: "is-success",
            message: "הפרטים נשמרו בהצלחה!",
            position: "is-top",
            duration: 4000,
          });
        })
        .catch(() => {
          Toast.open({
            type: "is-danger",
            message: "הפעולה לא התבצעה. יש לנסות שוב.",
            position: "is-bottom",
            duration: 8000,
          });
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
    onChange() {},
    onSearchCities({ search, loading, setData, setValue }) {
      if (search.length >= 3) {
        loading(true);
        return (
          DriverService.searchCity(1, 1000, search)
        )
          .then((r) => {
            if (!r.data.Items.length) {
              setValue({ Value: -1, Text: search });
            } else {
              setData(r.data.Items.map((i) => ({ Value: i.Id, Text: i.Name })));
              this.model.Street = null;
            }
          })
          .finally(() => {
            loading(false);
          });
      }

      return null;
    },
    onSearchCityAddress({ search, loading, setData, setValue }) {
      if (search.length >= 3) {
        loading(true);
        return (
          DriverService.searchCityAddress(this.model.City, 1, 1000, search)
        )
          .then((r) => {
            if (!r.data.Items.length) {
              setValue({ Value: -1, Text: search });
            } else {
              setData(r.data.Items.map((i) => ({ Value: i.Id, Text: i.Name })));
            }
          })
          .finally(() => {
            loading(false);
          });
      }

      return null;
    },
  },
  watch: {
    profileId() {
      this.model = { ...this.model, ...this.profileGeneral };
    },
  },
};
</script>
<style lang="scss" scoped>
::v-deep .v-select {
  background-color: #f5f8fa;
  box-shadow: none;
  border: 1px solid #cbd6e2;
}
::v-deep .InlineField__Component__Placeholder__Text {
  color: black !important;
  font-weight: bold !important;
}
.Profile__General__Page {
  padding: 20px 40px;

  .Profile__General__Page__Header {
    border-bottom: 1px solid silver;
    padding: 0px 10px 10px 10px;

    h1 {
      font-size: 30px;
    }
  }

  .Profile__General__Page__Form {
    margin-top: 20px;
    max-width: 450px;

    .Profile__General__Page__Form__Field {
      margin-top: 20px;

      ::v-deep {
        .field label {
          font-size: 15px;
          color: rgb(44, 62, 80);
        }

        input {
          background-color: #f5f8fa;
          box-shadow: none;
          border: 1px solid #cbd6e2;

          &:focus {
            border: 2px solid #5fe0ea;
          }
        }
      }
    }

    .Profile__General__Page__Form__Actions {
      ::v-deep .button {
        margin-inline-end: 5px;
        background-color: #4d6884;
        color: white;
        border: none;
        border-radius: 3px;
        padding: 6px 15px;
        line-height: normal;
        height: unset;
        cursor: pointer;
        box-shadow: none;
        transition: transfrom 0.2s linear;

        &:hover {
          background-color: #54779c;
          border: none;
          outline: none;
        }

        &:active {
          transform: scale(0.98);
          border: none;
          outline: none;
        }
      }
    }
  }
}
</style>
